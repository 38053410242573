<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" style="padding-bottom: var(--ion-safe-area-bottom, 0);">
        <ion-tab-button
          :class="{ 'is-active': routePath.includes('/home') }"
          @click="handleTabClick('/home')"
          tab="home"
          data-testid="home-tab"
          id="home-tab"
        >
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.1637 21H5.61821C3.06764 21 1 18.9323 1 16.3818V9.11688C1 7.50197 1.84353 6.00438 3.22458 5.16737L8.99735 1.66873C10.4686 0.77709 12.3134 0.77709 13.7846 1.66873L19.5574 5.16737C20.9384 6.00438 21.782 7.50197 21.782 9.11688V16.3818C21.782 18.9323 19.7143 21 17.1637 21Z"
              stroke="#656F9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="parent-hover:fill-primary" />
            <path d="M7.92725 16.3817H14.8546" stroke="#656F9C" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" class="parent-hover:fill-primary" />
          </svg>
        </ion-tab-button>

        <ion-tab-button
          :class="{ 'is-active': routePath.includes('/catalog') }"
          tab="catalog"
          data-testid="catalog-tab"
          @click="handleTabClick('/catalog')"
          id="catalog-tab"
        >
          <svg width="25" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.0043 20.3333V13.8888C14.0043 13.5206 14.3027 13.2222 14.6709 13.2222H21.1154C21.4836 13.2222 21.782 13.5206 21.782 13.8888V20.3333C21.782 20.7015 21.4836 20.9999 21.1154 20.9999H14.6709C14.3027 20.9999 14.0043 20.7015 14.0043 20.3333Z"
              stroke="#656F9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="parent-hover:fill-primary" />
            <path
              d="M1.78198 20.3333V13.8888C1.78198 13.5206 2.08046 13.2222 2.44865 13.2222H8.89309C9.26128 13.2222 9.55976 13.5206 9.55976 13.8888V20.3333C9.55976 20.7015 9.26128 20.9999 8.89309 20.9999H2.44865C2.08046 20.9999 1.78198 20.7015 1.78198 20.3333Z"
              stroke="#656F9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="parent-hover:fill-primary" />
            <path
              d="M14.0043 8.11111V1.66667C14.0043 1.29848 14.3027 1 14.6709 1H21.1154C21.4836 1 21.782 1.29848 21.782 1.66667V8.11111C21.782 8.4793 21.4836 8.77778 21.1154 8.77778H14.6709C14.3027 8.77778 14.0043 8.4793 14.0043 8.11111Z"
              stroke="#656F9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="parent-hover:fill-primary" />
            <path
              d="M1.78198 8.11111V1.66667C1.78198 1.29848 2.08046 1 2.44865 1H8.89309C9.26128 1 9.55976 1.29848 9.55976 1.66667V8.11111C9.55976 8.4793 9.26128 8.77778 8.89309 8.77778H2.44865C2.08046 8.77778 1.78198 8.4793 1.78198 8.11111Z"
              stroke="#656F9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="parent-hover:fill-primary" />
          </svg>
        </ion-tab-button>

        <ion-tab-button
          :class="{ 'is-active': routePath.includes('/cart') }"
          tab="cart"
          data-testid="cart-tab"
          @click="handleTabClick('/cart')"
          id="cart-tab"
        >
          <div class="relative">
            <div v-if="totalItems > 0"
              class="absolute font-lato left-4 flex items-center leading-3 -top-2 bg-secondary-300 border-2 border-white text-white rounded-full min-w-5 h-5 font-medium px-1 justify-center"
              style="font-size: 10px; z-index: 100">
              {{ totalItems }}
            </div>
            <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.37484 3.2499H23.499L21.2491 15.6243H6.62474L4.37484 3.2499ZM4.37484 3.2499C4.18735 2.49993 3.2499 1 1 1"
                stroke="#656F9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="parent-hover:fill-primary" />
              <path
                d="M11.6065 19.0713C11.6065 20.1363 10.743 20.9998 9.67799 20.9998C8.61292 20.9998 7.74951 20.1363 7.74951 19.0713"
                stroke="#656F9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="parent-hover:fill-primary" />
              <path
                d="M19.3213 19.0713C19.3213 20.1363 18.4579 20.9998 17.3928 20.9998C16.3278 20.9998 15.4644 20.1363 15.4644 19.0713"
                stroke="#656F9C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="parent-hover:fill-primary" />
            </svg>
          </div>
        </ion-tab-button>

        <ion-tab-button
          :class="{ 'is-active': routePath.includes('/information') }"
          tab="information"
          data-testid="information-tab"
          @click="handleTabClick('/information')"
          id="information-tab"
        >
          <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="9" stroke="#656F9C" stroke-width="2" />
            <path d="M10 9L10 15" stroke="#656F9C" stroke-width="2" stroke-linecap="round" />
            <circle cx="10" cy="6" r="1" fill="#656F9C" stroke="#656F9C" />
          </svg>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonPage,
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  useIonRouter,
} from '@ionic/vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { useCartStore } from '@/entities'
import { storeToRefs } from 'pinia';
const router = useIonRouter()

const cartStore = useCartStore()
const { totalItems } = storeToRefs(cartStore)

const route = useRoute();
const routePath = computed(() => route.path);

const handleTabClick = (path: string) => {
  console.log('handleTabClick', path)
  router.push(path)
}
</script>

<style scoped>
ion-tab-button {
  --color: var(--ion-color-medium);
  --color-selected: var(--ion-color-primary);
}

ion-tab-button::before {
  background: transparent;
}

ion-label {
  font-size: 12px;
}

svg {
  width: 24px;
  height: 24px;
}
</style>
