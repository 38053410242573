import { default as Axios, AxiosRequestConfig, AxiosInstance, AxiosHeaders, AxiosHeaderValue } from 'axios'
import { logError } from '../utils/error-logger'

export interface ApiInstance {
  $axios: AxiosInstance
  $request<T = any>(config: AxiosRequestConfig): Promise<T>
  $get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  $put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  $delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  $options<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
}

interface AxiosExtra {
  [key: string]: (...args: any[]) => Promise<any>;
}

export function createApi(): ApiInstance {
  const axiosExtra: AxiosExtra = {}

  const createAxiosInstance = (axiosOptions: AxiosRequestConfig) => {
    const axios = Axios.create(axiosOptions)
    ;(axios as any).CancelToken = Axios.CancelToken
    ;(axios as any).isCancel = Axios.isCancel

    extendAxiosInstance(axios)

    axios.interceptors.request.use((config) => {
      const headers = { ...axios.defaults.headers.common, ...config.headers }
      config.headers = new AxiosHeaders(headers as Record<string, AxiosHeaderValue>)
      config.url = encodeURI(config.url as string)
      return config
    })

    // Add response interceptor for error handling
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // Log API errors with relevant context
        logError(error, {
          url: error.config?.url,
          method: error.config?.method,
          status: error.response?.status,
          statusText: error.response?.statusText,
        });
        return Promise.reject(error);
      }
    );

    return axios
  }

  for (const method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
    axiosExtra['$' + method] = function (this: any, ...args: any[]) {
      return this[method](...args).then((res: any) => res && res.data)
    }
  }

  const extendAxiosInstance = (axios: AxiosInstance) => {
    for (const key in axiosExtra) {
      (axios as any)[key] = axiosExtra[key].bind(axios)
    }
  }

  const axiosOptions = {
    baseURL: import.meta.env.VITE_API_URL
  }

  const axios = createAxiosInstance(axiosOptions)

  return {
    ...axios as any,
    $axios: axios
  }
}

export const api = createApi()

export function transformFiltersToUrl(filters: Map<string, any>): string {
  return Array.from(filters.entries())
    .map(([key, value]) => `${key}-${Array.isArray(value) ? value.join('||') : value}`)
    .join(';')
}