import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export interface CartEntry {
  productId: string
  quantity: number
  // Add any other product-specific fields you might need
}

export interface CartState {
  items: Record<string, CartEntry>
}

export const useCartStore = defineStore('cart', () => {
  const state = ref<CartState>({
    items: {}
  })

  // Computed properties
  const totalItems = computed(() => {
    return Object.values(state.value.items).reduce((sum, item) => sum + item.quantity, 0)
  })

  const isEmpty = computed(() => Object.keys(state.value.items).length === 0)

  // Actions
  function addToCart(productId: string, quantity: number = 1) {
    if (quantity <= 0) return

    const currentItem = state.value.items[productId]
    if (currentItem) {
      currentItem.quantity += quantity
    } else {
      state.value.items[productId] = {
        productId,
        quantity
      }
    }
  }

  function updateQuantity(productId: string, quantity: number) {
    if (quantity <= 0) {
      removeFromCart(productId)
      return
    }

    if (state.value.items[productId]) {
      state.value.items[productId].quantity = quantity
    }
  }

  function removeFromCart(productId: string) {
    delete state.value.items[productId]
  }

  function clearCart() {
    state.value.items = {}
  }

  function getItemQuantity(productId: string): number {
    return state.value.items[productId]?.quantity || 0
  }

  function isInCart(productId: string): boolean {
    return !!state.value.items[productId]
  }

  function getCartItems(): CartEntry[] {
    return Object.values(state.value.items)
  }

  return {
    // State
    cart: state,
    
    // Computed
    totalItems,
    isEmpty,
    
    // Actions
    addToCart,
    updateQuantity,
    removeFromCart,
    clearCart,
    getItemQuantity,
    isInCart,
    getCartItems
  }
}, {
  persist: true
})