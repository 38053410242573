import dayjs from 'dayjs/esm/index.js'
import utc from 'dayjs/esm/plugin/utc/index.js'
import timezone from 'dayjs/esm/plugin/timezone/index.js'
import ru from 'dayjs/esm/locale/ru'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Moscow')

dayjs.locale(ru)


import {
  monthNamesShort,
  monthNames,
  weekDayNames
} from '../utils/constants'

export function useDate() {
  function getDate(date: string | Date) {
    return dayjs(date).tz('Europe/Moscow').get('date')
  }

  function getTime(date: string | Date) {
    return `${dayjs(date).tz('Europe/Moscow').format('HH:mm')}`
  }

  function getDayOfWeek(date: string | Date) {
    return weekDayNames[dayjs(date).tz('Europe/Moscow').day()]
  }

  function getMonth(date: string | Date) {
    return monthNames[dayjs(date).tz('Europe/Moscow').get('month')]
  }

  function getMonthNumber(date: string | Date) {
    return dayjs(date).tz('Europe/Moscow').format('MM')
  }

  function getShortMonth(date: string | Date) {
    return monthNamesShort[dayjs(date).tz('Europe/Moscow').get('month')]
  }

  function getYear(date: string | Date) {
    return dayjs(date).tz('Europe/Moscow').get('year')
  }

  function formatDate(date: string | Date, string: string) {
    return dayjs(date).tz('Europe/Moscow').format(string)
  }

  return {
    getDate,
    getTime,
    getDayOfWeek,
    getMonth,
    getMonthNumber,
    getShortMonth,
    getYear,
    formatDate
  }
}
