import { Device } from '@capacitor/device';
import { v4 as uuidv4 } from 'uuid';
import { useStorage } from '@vueuse/core';
import { Capacitor } from '@capacitor/core';

export const useDeviceInfo = () => {
  const userId = useStorage('user-id', uuidv4());

  const getBrowserInfo = () => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined') {
      return {
        userAgent: 'unknown',
        browserName: 'unknown',
        browserVersion: 'unknown',
        language: 'unknown',
        screenSize: 'unknown',
      };
    }
    
    const ua = navigator.userAgent;
    let browserName = 'unknown';
    let browserVersion = 'unknown';
    
    // Detect browser name and version
    if (ua.indexOf('Firefox') > -1) {
      browserName = 'Firefox';
      browserVersion = ua.match(/Firefox\/([0-9.]+)/)?.[1] || 'unknown';
    } else if (ua.indexOf('SamsungBrowser') > -1) {
      browserName = 'Samsung Browser';
      browserVersion = ua.match(/SamsungBrowser\/([0-9.]+)/)?.[1] || 'unknown';
    } else if (ua.indexOf('Opera') > -1 || ua.indexOf('OPR') > -1) {
      browserName = 'Opera';
      browserVersion = ua.match(/(?:Opera|OPR)\/([0-9.]+)/)?.[1] || 'unknown';
    } else if (ua.indexOf('Trident') > -1) {
      browserName = 'Internet Explorer';
      browserVersion = ua.match(/rv:([0-9.]+)/)?.[1] || 'unknown';
    } else if (ua.indexOf('Edge') > -1) {
      browserName = 'Edge (Legacy)';
      browserVersion = ua.match(/Edge\/([0-9.]+)/)?.[1] || 'unknown';
    } else if (ua.indexOf('Edg') > -1) {
      browserName = 'Edge (Chromium)';
      browserVersion = ua.match(/Edg\/([0-9.]+)/)?.[1] || 'unknown';
    } else if (ua.indexOf('Chrome') > -1) {
      browserName = 'Chrome';
      browserVersion = ua.match(/Chrome\/([0-9.]+)/)?.[1] || 'unknown';
    } else if (ua.indexOf('Safari') > -1) {
      browserName = 'Safari';
      browserVersion = ua.match(/Version\/([0-9.]+)/)?.[1] || 'unknown';
    }
    
    return {
      userAgent: ua,
      browserName,
      browserVersion,
      language: navigator.language,
      screenSize: `${window.screen.width}x${window.screen.height}`,
      screenDensity: window.devicePixelRatio,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      online: navigator.onLine,
    };
  };

  const getDeviceInfo = async () => {
    const info = await Device.getInfo();
    const deviceId = await Device.getId();
    const battery = await Device.getBatteryInfo().catch(() => ({ batteryLevel: -1, isCharging: false }));
    
    const deviceInfo = {
      // Core device info
      platform: info.platform,
      osVersion: info.osVersion,
      model: info.model,
      manufacturer: info.manufacturer,
      deviceId: deviceId.identifier,
      userId: userId.value,
      
      // Additional runtime info
      appVersion: import.meta.env.VITE_APP_VERSION || 'dev',
      appBuild: import.meta.env.VITE_APP_BUILD || 'local',
      isVirtual: info.isVirtual,
      webViewVersion: info.webViewVersion,
      operatingSystem: info.operatingSystem,
      
      // Battery info
      batteryLevel: battery.batteryLevel,
      isCharging: battery.isCharging,
      
      // Time info
      locale: Intl.DateTimeFormat().resolvedOptions().locale,
      timestamp: new Date().toISOString(),
    };
    
    // Add browser-specific information if on web platform
    if (Capacitor.getPlatform() === 'web') {
      return {
        ...deviceInfo,
        ...getBrowserInfo(),
      };
    }
    
    return deviceInfo;
  };

  return { getDeviceInfo, userId };
}; 