import { createApp } from "vue";
import { App, router } from "@/app";
import * as Sentry from "@sentry/vue";
import { IonicVue } from "@ionic/vue";
import { SplashScreen } from "@capacitor/splash-screen";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { App as CapacitorApp } from '@capacitor/app';
import { version } from '../package.json';

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

import "@/app/assets/css/tailwind.css";
import "@/app/assets/css/ionic.css";

import "@/app/assets/fonts/lato/stylesheet.css";

import { VueQueryPlugin } from "vue-query";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createYmaps } from "vue-yandex-maps";
import { StatusBar } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import { useDeviceInfo } from "@/shared/utils/useDeviceInfo";
import { isPlatform } from "@ionic/vue";
import { IonicConfig } from "@ionic/core";
import { BundleInfo, CapacitorUpdater } from '@capgo/capacitor-updater';

// Initialize the app and handle splash screen
const initializeApp = async () => {
  const { getDeviceInfo } = useDeviceInfo();
  const deviceInfo = await getDeviceInfo();

  // Check if running as a Capacitor app (iOS or Android)
  // Add a class to the body if running in a Capacitor app
  if (Capacitor.getPlatform() === "web") {
    document.body.classList.remove('capacitor');
  }

  if (Capacitor.getPlatform() !== "web") {
    CapacitorUpdater.notifyAppReady();
  }

  // Set up tags for Sentry
  const sentryTags = {
    // Platform info
    runtimePlatform: deviceInfo.platform,
    osVersion: deviceInfo.osVersion,
    model: deviceInfo.model,
    manufacturer: deviceInfo.manufacturer,
    deviceId: deviceInfo.deviceId,

    // Runtime info
    appVersion: deviceInfo.appVersion,
    appBuild: deviceInfo.appBuild,
    isVirtual: String(deviceInfo.isVirtual),
    webViewVersion: deviceInfo.webViewVersion,
    operatingSystem: deviceInfo.operatingSystem,

    // Device status
    batteryLevel: String(deviceInfo.batteryLevel),
    isCharging: String(deviceInfo.isCharging),
    locale: deviceInfo.locale,
  };

  // Add browser-specific information if on web
  if (deviceInfo.platform === "web" && "browserName" in deviceInfo) {
    const webInfo = deviceInfo as any;
    Object.assign(sentryTags, {
      browserName: webInfo.browserName,
      browserVersion: webInfo.browserVersion,
      language: webInfo.language,
      screenSize: webInfo.screenSize,
      screenDensity: String(webInfo.screenDensity),
      timezone: webInfo.timezone,
      online: String(webInfo.online),
    });
  }

  // Lock screen orientation to portrait
  try {
    if (Capacitor.getPlatform() !== "web") {
      // Consistent with Capacitor 7 API
      await ScreenOrientation.lock({ orientation: "portrait" });
      console.log("Screen orientation locked to portrait");
    }
  } catch (error) {
    console.error("Error locking screen orientation:", error);
  }

  // Show splash screen if it's not already visible
  // This ensures splash is shown even when navigating back to the app from background
  await SplashScreen.show({
    showDuration: 3000,
    autoHide: false, // We'll manually hide it when app is ready
  });

  const getConfig = () => {
    let config: IonicConfig = {
    };
    if (isPlatform("mobileweb")) {
      config = {
        ...config,
        hardwareBackButton: false,
        swipeBackEnabled: false,
        animated: false,
      };
    }

    return config;
  };

  const app = createApp(App)
    .use(IonicVue, getConfig())
    .use(router)
    .use(VueQueryPlugin, {
      queryClientConfig: {
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            networkMode: "offlineFirst",
          },
        },
      },
    })
    .use(createPinia().use(piniaPluginPersistedstate))
    .use(
      createYmaps({
        apikey: "c1898160-5ec0-40ed-aec9-f8f211209135",
        lang: "ru_RU",
      })
    );

  if (import.meta.env.PROD) {
    Sentry.init({
      app,
      dsn: "https://64618c6b941bf0814320fe1556ce3039@sentry.a-it.org/3",
      environment: import.meta.env.ENVIRONMENT,
      release: version,
      initialScope: {
        tags: sentryTags,
        user: {
          id: deviceInfo.userId,
        },
      },
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration({
          maskAllInputs: false,
          maskAllText: false,
        }),
        Sentry.captureConsoleIntegration({
          levels: ["error", "warn"],
        }),
      ],
      profilesSampleRate: 1.0,
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/api.positive.ooo\/api/,
        /^https:\/\/mobile.positive.ooo/,
        /^https:\/\/cdn.a-it.org/,
      ],
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  // Add global error handler for unhandled errors
  app.config.errorHandler = (err, instance, info) => {
    console.error("Unhandled application error:", err);
    console.error("Error info:", info);

    // Sentry will automatically capture console.error calls,
    // but we can also explicitly capture the error with context
    Sentry.captureException(err, {
      extra: {
        componentInfo: info,
        componentName: instance?.$options?.name || "Unknown component",
      },
    });
  };

  // Wait for router to be ready
  await router.isReady();

  // Mount the app
  app.mount("#app");

  // Add a small delay to ensure app is fully rendered
  setTimeout(async () => {
    try {
      if (Capacitor.getPlatform() !== "web") {
        StatusBar.setBackgroundColor({ color: "#343A51" });
        StatusBar.setOverlaysWebView({ overlay: false });

        // Hide the splash screen with a fade animation for native platforms
        await SplashScreen.hide({
          fadeOutDuration: 500,
        });
        console.log("Splash screen hidden successfully");
      } else {
        // For web platform, hide our custom preloader
        if (typeof window.hideWebPreloader === 'function') {
          window.hideWebPreloader();
        }
      }
    } catch (error) {
      console.error("Error in app initialization:", error);

      // If there was an error but we're on web, still try to hide the preloader
      if (Capacitor.getPlatform() === "web" && typeof window.hideWebPreloader === 'function') {
        window.hideWebPreloader();
      }
    } finally {
      if (typeof window.hideWebPreloader === 'function') {
        window.hideWebPreloader();
      }
    }
  }, 500);

  let data: BundleInfo | null = null

  if (Capacitor.getPlatform() !== "web") {
    CapacitorApp.addListener('appStateChange', async (state: any) => {
      console.log('appStateChange', state)
      if (state.isActive) {
        console.log('getLatest')
        // Do the download during user active app time to prevent failed downloads
        const latest = await CapacitorUpdater.getLatest()
        console.log('latest', latest)
        if (latest.url) {
          data = await CapacitorUpdater.download({
            url: latest.url,
            version: latest.version,
          })
          console.log('download', data)
        }
      }
      if (!state.isActive && data) {
        console.log('set')
        // Do the switch when user leaves the app or when you want
        SplashScreen.show()
        try {
          await CapacitorUpdater.set({ id: data.id })
        }
        catch (err) {
          console.log(err)
        } finally {
          SplashScreen.hide()
        }
      }
    })
  }
};

// Initialize the app
initializeApp();

// Global handler for unhandled promise rejections
window.addEventListener("unhandledrejection", (event) => {
  console.error("Unhandled promise rejection:", event.reason);

  // Sentry will automatically capture console.error calls,
  // but we can also explicitly capture the error
  Sentry.captureException(event.reason, {
    extra: {
      unhandledRejection: true,
    },
  });
});

if (import.meta.env.ENVIRONMENT === 'production') {
  (function (m, e, t, r, i, k, a) {
    // @ts-expect-error yandex metrika
    // eslint-disable-next-line
    m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
    // @ts-expect-error yandex metrika
    m[i].l = 1 * new Date();
    for (let j = 0; j < document.scripts.length; j++) { if (document.scripts[j].src === r) { return; } }
    // @ts-expect-error yandex metrika
    // eslint-disable-next-line
    k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
  })
    // eslint-disable-next-line
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

  // @ts-expect-error yandex metrika
  ym(97385419, "init", {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: "dataLayer"
  });
}
