import { IProduct } from "@/shared";

export const placeholderProduct: IProduct = {
  public_id: '',
  name: '',
  isPublished: false,
  attributes: [],
  price: 0,
  images: [],
  description: '',
  store: {
    public_id: '',
    name: ''
  },
  category: undefined,
  imageUrl: '',
  vendorCode: '',
  code: '',
  snippet: '',
  monthWarranty: 0,
  count: 0,
  unitOfMeasurement: '',
  updatedAt: new Date(),
  createdAt: new Date(),
  publishedDate: new Date(),
  unpublishedDate: new Date()
}

export const placeholderProducts = new Array(4).fill(placeholderProduct) as IProduct[];
