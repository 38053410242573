import { api, transformFiltersToUrl } from './base'
import type { ICategory } from '@/shared'

export interface CategoryTree {
  id: string
  label: string
  image: string
  icon: string
  parent_id: string | null
  priority: number
  children: CategoryTree[]
}

function processCategoriesTree(categories: ICategory[]): CategoryTree[] {
  if (!categories?.length) {
    return []
  }

  const sortedCategories = categories.sort((a, b) => a.name.localeCompare(b.name))
  const tree = Object.fromEntries(
    sortedCategories.map((category) => [
      category.public_id,
      {
        parent_id: category.parent_id,
        label: category.name,
        image: category.imageUrl,
        icon: category?.iconUrl || '',
        children: [],
        id: category.public_id,
        priority: category.priority
      } as CategoryTree,
    ])
  )

  const filterTree = Object.values(tree).filter(
    (node) => {
      if (!node.parent_id || !tree[node.parent_id]) {
        return true;
      }
      tree[node.parent_id].children.push(node);
      return false;
    }
  )

  return filterTree.sort((a, b) => a.priority - b.priority)
}

export const categoriesApi = {
  getCategories: async (): Promise<ICategory[]> => {
    return await api.$get<ICategory[]>('/api/v1/category')
  },

  getAllCategories: async (): Promise<CategoryTree[]> => {
    const categories = await api.$get<ICategory[]>('/api/v1/category')
    return processCategoriesTree(categories)
  },

  getCategory: async (categoryId: string): Promise<ICategory> => {
    return await api.$get(`/api/v1/category/${categoryId}`)
  },

  getCategoryFilters: async (categoryId: string, filters?: {
    minPrice?: number | null
    maxPrice?: number | null
    filter?: Map<string, any>
    range?: Map<string, any>
  }) => {
    return await api.$get(`/api/v1/category/${categoryId}/category-filters`, {
      params: {
        minPrice: filters?.minPrice,
        maxPrice: filters?.maxPrice,
        filter: filters?.filter ? transformFiltersToUrl(filters.filter) : null,
        rangeFilter: filters?.range ? transformFiltersToUrl(filters.range) : null,
      }
    })
  },

  searchCategories: async (query: string, limit: number = 100): Promise<ICategory[]> => {
    return await api.$get('/api/v1/category/get/search', {
      params: {
        searchString: query,
        limit
      }
    })
  }
} 