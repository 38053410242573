import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsLayout from '../layout/TabsLayout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/',
    component: TabsLayout,
    children: [
      {
        path: '/',
        redirect: '/home'
      },
      {
        path: '/home',
        component: () => import(/* webpackChunkName: "home" */ '../../pages/home/home-page.vue')
      },
      {
        path: '/home/search',
        component: () => import(/* webpackChunkName: "search" */ '../../pages/search/ui/search-page.vue')
      },
      {
        path: '/catalog',
        component: () => import(/* webpackChunkName: "catalog" */ '../../pages/catalog/ui/catalog-page.vue')
      },
      {
        path: '/catalog/popular',
        component: () => import(/* webpackChunkName: "popular" */ '../../pages/popular/ui/popular-page.vue')
      },
      {
        path: '/catalog/:level1Id',
        component: () => import(/* webpackChunkName: "subcatalog" */ '../../pages/catalog/ui/sub-catalog-page.vue'),
        props: true,
      },
      {
        path: '/catalog/:level1Id/products',
        component: () => import(/* webpackChunkName: "product-list" */ '../../pages/product-list/ui/product-list-page.vue'),
        props: true,
      },
      {
        path: '/catalog/:level1Id/:level2Id',
        component: () => import(/* webpackChunkName: "subcatalog" */ '../../pages/catalog/ui/sub-catalog-page.vue'),
        props: true,
      },
      {
        path: '/catalog/:level1Id/:level2Id/products',
        component: () => import(/* webpackChunkName: "product-list" */ '../../pages/product-list/ui/product-list-page.vue'),
        props: true,
      },
      {
        path: '/catalog/:level1Id/:level2Id/:level3Id',
        component: () => import(/* webpackChunkName: "subcatalog" */ '../../pages/catalog/ui/sub-catalog-page.vue'),
        props: true,
      },
      {
        path: '/catalog/:level1Id/:level2Id/:level3Id/products',
        component: () => import(/* webpackChunkName: "product-list" */ '../../pages/product-list/ui/product-list-page.vue'),
        props: true,
      },
      {
        path: '/catalog/:level1Id/:level2Id/:level3Id/:level4Id',
        component: () => import(/* webpackChunkName: "subcatalog" */ '../../pages/catalog/ui/sub-catalog-page.vue'),
        props: true,
      },
      {
        path: '/catalog/:level1Id/:level2Id/:level3Id/:level4Id/products',
        component: () => import(/* webpackChunkName: "product-list" */ '../../pages/product-list/ui/product-list-page.vue'),
        props: true,
      },
      {
        path: '/catalog/:level1Id/products/product/:productId',
        component: () => import(/* webpackChunkName: "product-detail" */ '../../pages/product-detail/ui/product-detail.vue'),
        props: true,
      },
      {
        path: '/catalog/:level1Id/:level2Id/products/product/:productId',
        component: () => import(/* webpackChunkName: "product-detail" */ '../../pages/product-detail/ui/product-detail.vue'),
        props: true,
      },
      {
        path: '/catalog/:level1Id/:level2Id/:level3Id/products/product/:productId',
        component: () => import(/* webpackChunkName: "product-detail" */ '../../pages/product-detail/ui/product-detail.vue'),
        props: true,
      },
      {
        path: '/catalog/:level1Id/:level2Id/:level3Id/:level4Id/products/product/:productId',
        component: () => import(/* webpackChunkName: "product-detail" */ '../../pages/product-detail/ui/product-detail.vue'),
        props: true,
      },
      {
        path: '/catalog/product/:productId',
        component: () => import(/* webpackChunkName: "product-detail" */ '../../pages/product-detail/ui/product-detail.vue'),
        props: true,
      },
      {
        path: '/cart',
        component: () => import(/* webpackChunkName: "cart" */ '../../pages/cart/ui/cart-page.vue')
      },
      {
        path: '/information',
        component: () => import(/* webpackChunkName: "information" */ '../../pages/information/ui/information-page.vue')
      },
      {
        path: '/information/services',
        component: () => import(/* webpackChunkName: "information-services" */ '../../pages/information/ui/services-page.vue')
      },
      {
        path: '/information/about',
        component: () => import(/* webpackChunkName: "information-about" */ '../../pages/information/ui/about-page.vue')
      },
      {
        path: '/information/vacancies',
        component: () => import(/* webpackChunkName: "information-vacancies" */ '../../pages/information/ui/vacancies-page.vue')
      },
      {
        path: '/information/delivery',
        component: () => import(/* webpackChunkName: "information-delivery" */ '../../pages/information/ui/delivery-page.vue')
      },
      {
        path: '/information/guide-order',
        component: () => import(/* webpackChunkName: "information-guide" */ '../../pages/information/ui/guide-order-page.vue')
      },
      {
        path: '/information/legal-entities',
        component: () => import(/* webpackChunkName: "information-legal" */ '../../pages/information/ui/legal-entities-page.vue')
      },
      {
        path: '/information/contacts',
        component: () => import(/* webpackChunkName: "information-contacts" */ '../../pages/information/ui/contacts-page.vue')
      },
      {
        path: '/information/favorites',
        component: () => import(/* webpackChunkName: "favorites" */ '../../pages/favorites-page/index.vue')
      },
      {
        path: '/information/about-app',
        component: () => import(/* webpackChunkName: "information-app" */ '../../pages/information/ui/about-app-page.vue')
      },
      {
        path: '/:slug1/product/:productId',
        component: () => import(/* webpackChunkName: "product-detail" */ '../../pages/product-detail/ui/product-detail.vue')
      },
      {
        path: '/:slug1/:slug2/product/:productId',
        component: () => import(/* webpackChunkName: "product-detail" */ '../../pages/product-detail/ui/product-detail.vue')
      },
      {
        path: '/:slug1/:slug2/:slug3/product/:productId',
        component: () => import(/* webpackChunkName: "product-detail" */ '../../pages/product-detail/ui/product-detail.vue')
      },
      {
        path: 'product/:productId',
        component: () => import(/* webpackChunkName: "product-detail" */ '../../pages/product-detail/ui/product-detail.vue')
      },
    ]
  },
  {
    path: '/checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '../../pages/checkout/ui/checkout-page.vue')
  },
  {
    path: '/checkout/success',
    component: () => import(/* webpackChunkName: "checkout-success" */ '../../pages/checkout/ui/checkout-success-page.vue')
  },
  {
    path: '/seasonal',
    component: () => import(/* webpackChunkName: "seasonal" */ '../../pages/marketing-pages/ui/seasonal-page.vue')
  },
  {
    path: '/gift-ideas',
    component: () => import(/* webpackChunkName: "gift-ideas" */ '../../pages/marketing-pages/ui/gift-ideas-page.vue')
  }
] 

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

export default router
