import * as Sentry from '@sentry/vue';

/**
 * Logs an error to both console and Sentry
 * @param error The error to log
 * @param context Optional additional context
 */
export const logError = (error: Error | unknown, context?: Record<string, any>) => {
  // Always log to console first
  console.error(error);
  
  // The console integration will automatically capture this in Sentry
  // But we can add extra context if provided
  if (context) {
    Sentry.withScope(scope => {
      scope.setContext('additional_context', context);
      
      if (error instanceof Error) {
        Sentry.captureException(error);
      } else {
        Sentry.captureMessage(String(error), 'error');
      }
    });
  }
};

/**
 * Logs a warning to both console and Sentry
 * @param message The warning message
 * @param context Optional additional context
 */
export const logWarning = (message: string, context?: Record<string, any>) => {
  // Always log to console first
  console.warn(message);
  
  // The console integration will automatically capture this in Sentry
  // But we can add extra context if provided
  if (context) {
    Sentry.withScope(scope => {
      scope.setContext('additional_context', context);
      Sentry.captureMessage(message, 'warning');
    });
  }
};
