<template>
  <ion-app>
    <ion-router-outlet :animated="isAnimated" />
  </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { useCategoryStore } from '@/entities';
import { isPlatform } from '@ionic/vue';

const isAnimated = isPlatform("mobileweb");

const categoryStore = useCategoryStore();
categoryStore.initCategoryStore();
</script>
