import { defineStore } from "pinia";
import { ref } from "vue";
import { getAllCategories } from '../api'

interface ICategoryTree {
  id: string;
  label: string;
  image: string;
  icon: string;
  parent_id: string | null;
  priority: number;
  children: ICategoryTree[];
}

export const useCategoryStore = defineStore("category-store", () => {
  const categories = ref<ICategoryTree[]>([]);

  function clearCategoryStoreCategory() {
    categories.value = [] as ICategoryTree[];
  }

  function setCategoryStore(__categories: ICategoryTree[]) {
    categories.value = __categories as ICategoryTree[];
  }

  async function initCategoryStore() {
    const response = await getAllCategories();
    categories.value = response as ICategoryTree[];
  }

  function findCategoryInTree(id: string, categories: ICategoryTree[]): ICategoryTree | undefined {
    for (const category of categories) {
      if (category.id === id) {
        return category;
      }
      if (category.children?.length) {
        const found = findCategoryInTree(id, category.children);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  function getCategoryById(id: string): ICategoryTree | undefined {
    return findCategoryInTree(id, categories.value);
  }


  return {
    categories,
    clearCategoryStoreCategory,
    setCategoryStore,
    initCategoryStore,
    getCategoryById
  };
}, {
  persist: true
});
